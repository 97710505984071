<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-06 15:39:56
 * @LastEditTime: 2023-05-16 17:40:17
 * @Descripttion: 学生管理
-->
<style lang="scss" scoped>
.student {
    @include innerPage(40px 32px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 194px), 48px);
    @include pageFoot;

    .page-title {
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;

        .search-form {
            width: 50.75%;
            min-width: 720px;

            .el-form {
                width: calc(100% - 114px);
            }
        }

        .tool {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            margin-top: 124px;
            box-sizing: border-box;
            padding-left: 14px;
            @include flexBox(space-between);
        }
    }

    &-statistics {
        width: 704px;
        height: 90px;
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        @include flexBox(space-between);

        li {
            width: 228px;
            height: 100%;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 0 26px 0 30px;
            @include flexBox(space-between);

            .statistics-info {
                color: #fff;
                flex-direction: column;
                line-height: 24px;
                @include flexBox(center, flex-start);

                .pf_bold {
                    font-size: 30px;
                    line-height: 40px;

                    .pf {
                        font-size: 14px;
                        margin-left: 2px;
                    }
                }
            }

            .icon-box {
                width: 66px;
                height: 66px;
                border-radius: 20px;
                @include flexBox(center)
            }

            &:nth-child(1) {
                @include dotBgOrangered;

                .icon-box {
                    background: rgba($color: #e07263, $alpha: 1.0);
                    box-shadow: 0px 0px 57px 0px rgba(141, 17, 0, 0.5);
                }
            }

            &:nth-child(2) {
                @include dotBgGreen;

                .icon-box {
                    background: rgba($color: #4cb76a, $alpha: 1.0);
                    box-shadow: 0px 0px 57px 0px rgba(2, 101, 31, 0.6);
                }
            }

            &:nth-child(3) {
                @include dotBgPurple;

                .icon-box {
                    background: rgba($color: #8773ed, $alpha: .6);
                    box-shadow: 0px 0px 57px 0px rgba(22, 7, 157, 0.5);
                }
            }
        }
    }
}

.dialog-container {
    box-sizing: border-box;
    padding: 36px 0;

    &--parents {
        max-height: 480px;
        box-sizing: border-box;
        padding-right: 26px;
        overflow-y: auto;

        .parent {
            font-size: 16px;
            margin-bottom: 16px;
            @include flexBox(center);

            .relation {
                display: inline-block;
                width: 62px;
                height: 62px;
                border-radius: 50%;
                text-align: center;
                line-height: 62px;
                color: #fff;
                background: rgba($color: #b3b3b3, $alpha: .43);
            }

            &-info {
                margin-left: 18px;
                flex-direction: column;
                color: #252525;
                line-height: 24px;
                @include flexBox(center, flex-start);
            }

            &.father .relation {
                background: rgba($color: #6340c8, $alpha: .43);
            }

            &.mather .relation {
                background: rgba($color: #ee4f65, $alpha: .43);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--student {
        box-sizing: border-box;
        padding: 42px 0 22px;

        .tips {
            color: #e58181;
            height: 24px;
            position: absolute;
            top: 24px;
            right: 120px;
            font-size: 14px;
            @include flexBox;

            .iconfont {
                font-size: 20px;
                margin-right: 4px;
            }
        }

        .el-form {
            justify-content: space-between;
        }

        .el-form-item {
            width: 444px;
            margin-bottom: 20px;
        }
    }

    &.no-data {
        height: 300px;
    }

    .no-data--empty {
        img {
            width: 180px;
        }
    }
}
</style>

<template>
    <section class="student">
        <div class="page-title">
            <h3>学生管理</h3>
            <ul class="student-statistics">
                <li>
                    <div class="statistics-info">
                        <p class="pf_bold">{{ wacom_data.stu_count }}</p>
                        <p class="light">全班总人数</p>
                    </div>
                    <p class="icon-box">
                        <img src="@assets/images/student-icon1.png" alt="">
                    </p>
                </li>
                <li>
                    <div class="statistics-info">
                        <p class="pf_bold">{{ wacom_data.sure_wacom }}</p>
                        <p class="light">手写板数量</p>
                    </div>
                    <p class="icon-box">
                        <img src="@assets/images/student-icon2.png" alt="">
                    </p>
                </li>
                <li>
                    <div class="statistics-info">
                        <p class="pf_bold">
                            <span>{{ wacom_data.coverage_rate }}</span>
                            <span class="pf">%</span>
                        </p>
                        <p class="light">手写板覆盖率</p>
                    </div>
                    <p class="icon-box">
                        <img src="@assets/images/student-icon2.png" alt="">
                    </p>
                </li>
            </ul>
            <div class="tool">
                <div class="search-form">
                    <el-form inline :model="searchForm">
                        <el-form-item>
                            <el-cascader v-model="sccla" :options="gradeClass"
                                :props="{ value: 'sccla_id', label: 'sccla_name' }" collapse-tags />
                        </el-form-item>
                        <el-form-item style="flex-grow: 1">
                            <el-input v-model.trim="searchForm.query_word" maxlength="100" clearable placeholder="输入关键字">
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="custom_primary" @click="init('search')" size="medium">搜索</el-button>
                    <el-button class="iconfont mini" type="custom_success" @click="resetSearch" circle>&#xe608;</el-button>
                </div>
                <el-button type="custom_primary" size="medium" @click="studentAdd">
                    新增学生+
                </el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="stuser_name" label="学生姓名" min-width="12.05%" />
                    <el-table-column align="center" label="性别" min-width="7.19%">
                        <template slot-scope="scope">
                            {{ scope.row.stuser_sex == 10 && '男' || '女' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_account" label="账号" min-width="11.85%" />
                    <el-table-column align="center" prop="stuser_stuno" label="学号" min-width="11.85%" />
                    <el-table-column align="center" label="状态" min-width="7.89%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ danger: scope.row.stuser_readstatus == 20, success: scope.row.stuser_readstatus == 10 }">
                                {{ scope.row.stuser_readstatus == 10 && '在读' || '休学' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="账号状态" min-width="7.89%" prop="stuser_accstatus">
                        <template slot-scope="scope">
                            <!-- <p class="table-status"
                                :class="{ danger: scope.row.stuser_accstatus == 20, success: scope.row.stuser_accstatus == 10 }">
                                {{ scope.row.stuser_accstatus == 10 && '启用' || '禁用' }}
                            </p> -->
                            <el-switch v-model="scope.row.stuser_accstatus" :active-value="10" :inactive-value="20"
                            :active-text="scope.row.stuser_accstatus == 10 && '启用' || '禁用'" 
                            @change="
                            handleUpdateField(
                            scope.row.stuser_id,
                            'stuser_accstatus',
                            scope.row.stuser_accstatus
                            )
                  "/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_province_city_area" label="省-市-区" min-width="22.04%" />
                    <el-table-column align="center" label="操作" min-width="19.24%">
                        <template slot-scope="scope">
                            <el-button type="custom_success" plain size="mini"
                                @click="$router.push({ name: 'USER_STUDENT_DETAIL', params: { id: scope.row.stuser_id } })">
                                查看
                            </el-button>
                            <el-button type="custom_primary" plain size="mini" @click="studentEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="custom_warning" plain size="mini" @click="enquireParents(scope.row)">
                                家长
                            </el-button>
                            <!-- <el-button type="custom_danger" plain size="mini" @click="handleDelete(scope.row)">
                                删除
                            </el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='searchForm.pageindex' :total="total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog title="查看家长" width="440px" :visible.sync="parentsDialog">
            <div class="dialog-container" :class="{ 'no-data': $isEmpty(parentsData) }">
                <ul class="dialog-container--parents" v-if="!$isEmpty(parentsData)">
                    <li class="parent father" v-for="item in parentsData" :key="item.pauser_id">
                        <span class="relation bold">{{ item.pauser_stuser_parentage }}</span>
                        <p class="parent-info">
                            <span class="bold">{{ item.pauser_name }}</span>
                            <span class="pf">{{ item.pauser_phone }}</span>
                        </p>
                    </li>
                </ul>
                <div class="no-data--empty" v-if="$isEmpty(parentsData)">
                    <img src="@assets/images/no-data3.png" alt="">
                    <p>暂无家长信息哦~</p>
                </div>
            </div>
        </el-dialog>
        <el-dialog :title="type == 'update' && '编辑信息' || '新增学生'" width="1034px" :visible.sync="studentDialog"
            :show-close="false" @close="hideDialog">
            <div class="dialog-container--student">
                <p class="tips">
                    <i class="iconfont">&#xe63c;</i>
                    账号默认密码：{{ defaultPassword }}
                </p>
                <el-form label-width="82px" ref="studentForm" :rules="studentsRules" inline :model="studentForm">
                    <el-form-item label="学生姓名" prop="stuser_name">
                        <el-input v-model.trim="studentForm.stuser_name" maxlength="100" placeholder="请输入姓名" />
                    </el-form-item>
                    <el-form-item label="性别" prop="stuser_sex">
                        <el-select v-model="studentForm.stuser_sex" placeholder="请选择性别">
                            <el-option label="男" :value="10" />
                            <el-option label="女" :value="20" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账号" prop="stuser_account">
                        <el-input v-model.trim="studentForm.stuser_account" :readonly="type == 'update'" maxlength="100"
                            placeholder="请输入账号" />
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model.trim="studentForm.stuser_password" type="password" maxlength="16"
                            :placeholder="type == 'update' ? '不更改则不输入(8~16位)' : '不输入则为默认密码(8~16位)'" />
                    </el-form-item>
                    <!-- <el-form-item label="学号" prop="stuser_stuno"> -->
                    <el-form-item label="学号">
                        <el-input v-model.trim="studentForm.stuser_stuno" maxlength="100" placeholder="请输入学号（非必填）" />
                    </el-form-item>
                    <el-form-item label="地区" prop="stuser_city">
                        <el-cascader v-model="studentForm.stuser_city" ref="cascader" clearable :options="province"
                            :props="{ value: 'ddi_id', label: 'ddi_name' }" placeholder="请选择区域"
                            @change="cascaderChange($event, 'cascader')" />
                    </el-form-item>
                    <el-form-item label="班级" prop="sccla" v-if="type != 'update'">
                        <el-cascader v-model="studentForm.sccla" ref="cascader2" :options="gradeClass"
                            :props="{ value: 'sccla_id', label: 'sccla_name' }" clearable collapse-tags
                            @change="cascaderChange($event, 'cascader2')" />
                    </el-form-item>
                    <el-form-item label="班级" v-else>
                        <el-input v-model.trim="studentForm.class_name" :readonly="type == 'update'" maxlength="100"
                            placeholder="请输入账号" />
                    </el-form-item>
                    <el-form-item label="学生状态" prop="stuser_readstatus">
                        <el-select v-model="studentForm.stuser_readstatus" placeholder="请选择学生状态">
                            <el-option label="在读" :value="10" />
                            <el-option label="休学" :value="20" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账号状态" prop="stuser_accstatus">
                        <el-select v-model="studentForm.stuser_accstatus" placeholder="请选择账号状态">
                            <el-option label="启用" :value="10" />
                            <el-option label="禁用" :value="20" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" :class="[type == 'update' && 'foot-flex']">
                <!-- <el-button style="margin-right:auto" type="custom_warning" size="small" v-if="type == 'update'">
                    重置密码
                </el-button> -->
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="handleConfirm" :disabled="btnload"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { studentsList, studentsAdd, studentsEdit, studentsInfo, studentsSelectParent, updatePostField} from "@api/user";
import customPagination from "@comp/customPagination"
import { mapState } from "vuex";
export default {
    name: 'user_student',
    components: {
        customPagination
    },
    data() {
        return {
            stuser_accstatus:10,
            searchForm: {
                pageindex: 1,
                pagesize: 10,
            },
            tableData: [],
            total: 0,
            parentsDialog: false,
            parentsData: [],
            studentDialog: false,
            defaultPassword: "",
            wacom_data: {},
            type: "add",
            studentForm: {},
            btnload: false,
            sccla: [],
            studentsRules: {
                stuser_name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
                stuser_sex: [
                    { required: true, message: "请选择性别", trigger: "change" },
                ],
                stuser_account: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                // stuser_stuno: [
                //     { required: true, message: "请输入学号", trigger: "blur" },
                // ],
                stuser_readstatus: [
                    { required: true, message: "请选择学生状态", trigger: "change" },
                ],
                stuser_accstatus: [
                    { required: true, message: "请选择账号状态", trigger: "change" },
                ],
                stuser_city: [
                    {
                        type: "array",
                        required: true,
                        message: "请选择地区",
                        trigger: "change",
                    }],
                sccla: [
                    {
                        type: "array",
                        required: true,
                        message: "请选择班级",
                        trigger: "change",
                    },
                ],
            },
        }
    },
    created() {
        this.init();
    },
    computed: {
        ...mapState("common", ["province", "gradeClass"]),
    },
    methods: {
        /** 切换页码 */
        flippingPage(val) {
            this.searchForm.pageindex = val;
            this.init();
        },
        /**初始化 */
        async init(val) {
            if (val == "search") this.searchForm.pageindex = 1;
            if (this.sccla.length > 0) {
                this.searchForm.sccla_grade = this.sccla[0];
                this.searchForm.sccla_id = this.sccla[1];
            }
            const res = await studentsList(this.searchForm);
            if (res.status == 200) {
                this.tableData = res.data.data;
                this.defaultPassword = res.data.stuser_default_password;
                this.wacom_data = res.data.wacom_data;
                this.total = res.data.allcount;
            }
        },
        /** 添加学生 */
        async studentAdd() {
            this.type = "add";
            this.studentForm = { sccla: [], stuser_city: [] };
            this.studentDialog = true;
            this.$nextTick(() => {
                this.$refs.studentForm.clearValidate();
            });
        },
        /** 编辑学生 */
        async studentEdit(row) {
            this.type = "update";
            const res = await studentsInfo({ stuser_id: row.stuser_id });
            if (res.status == 200) {
                this.studentForm = {
                    ...res.data,
                    stuser_city: [res.data.stuser_province_id, res.data.stuser_city_id, res.data.stuser_area_id],
                    sccla: [res.data.sccla_grade, res.data.sccla_id],
                    stuser_password: '',
                }
                this.studentDialog = true;
            }
            this.$nextTick(() => {
                this.$refs.studentForm.clearValidate();
            });
        },
        /** 确认添加 */
        async handleConfirm(val) {
            this.$refs.studentForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let params = {
                        sccla_id: this.studentForm.sccla[1],
                        stuser_name: this.studentForm.stuser_name,
                        stuser_sex: this.studentForm.stuser_sex,
                        stuser_stuno: this.studentForm.stuser_stuno,
                        stuser_account: this.studentForm.stuser_account,
                        stuser_password: this.studentForm.stuser_password,
                        stuser_readstatus: this.studentForm.stuser_readstatus,
                        stuser_accstatus: this.studentForm.stuser_accstatus,
                        stuser_province_id: this.studentForm.stuser_city[0],
                        stuser_city_id: this.studentForm.stuser_city[1],
                        stuser_area_id: this.studentForm.stuser_city[2],
                        sccla_grade: this.studentForm.sccla[0],
                    };
                    let res = {};
                    if (this.type == "add") {
                        res = await studentsAdd(params);
                    } else {
                        params.stuser_id = this.studentForm.stuser_id;
                        res = await studentsEdit(params);
                    }
                    this.btnload = false;
                    if (res.status == 200) {
                        this.$message.success(this.type == "add" ? "添加成功" : "编辑成功");
                        this.hideDialog();
                        this.init();
                    }
                }
            });
        },
        /** 查看家长 */
        async enquireParents(row) {
            this.type = "update";
            const res = await studentsSelectParent({ stuser_id: row.stuser_id });
            if (res.status == 200) {
                this.parentsData = res.data;
                this.parentsDialog = true;
            }
        },
        /** 重置搜索 */
        resetSearch() {
            this.searchForm = {
                pageindex: 1,
                pagesize: 10,
            }
            this.sccla = [];
            this.init('search')
        },
        /** 级联选择器数据切换 */
        cascaderChange(val, ref) {
            if (this.$isEmpty(val)) {
                this.$nextTick(() => {
                    this.$refs[ref].$refs.panel.clearCheckedNodes();
                    this.$refs[ref].$refs.panel.activePath = [];
                })
            }
        },
        /** 关闭弹窗 */
        hideDialog(){
            this.studentDialog = false;
            this.$refs.studentForm.resetFields();
            this.studentForm = {}
        },
        /** 变更值 */
        async handleUpdateField(id, field, value) {
            let res = await updatePostField(id, field, value);
            if (res.status != 200) return;
            this.$message({
                message: res.msg,
                type: "success",
            });
            this.init();// 请求成功后，调用方法，更新页面数据
        },
    },
}
</script>